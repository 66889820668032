@import "../../styles/global.scss";

.container {
  grid-row: 4 / 5;
  grid-column: center-start / center-end;
  margin-top: 8rem;
  margin-bottom: 8rem;
  display: grid;
  row-gap: 5rem;

  @include respond(phone) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  & h4 {
    color: $color-primary-dark;
    text-align: center;
  }

  &-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    column-gap: 4rem;
    row-gap: 4rem;

    &__card {
      padding: 2.5rem 2.5rem;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
      border-radius: 15px;

      display: grid;
      justify-content: center;
      justify-items: center;
      row-gap: 1rem;

      &--img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

        @include respond(phone) {
          width: 140px;
          height: 140px;
        }
      }

      & h5 {
        margin-top: 1.5rem;
        font-size: 2rem;
        font-weight: 800;
        color: $color-primary-dark;
      }

      & p {
        color: $color-primary-dark;
        text-align: center;
      }

      & div {
        display: flex;
        justify-content: center;

        & img {
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }
}
