@import "../../styles/global.scss";

.container {
  grid-row: 6 / 7;
  grid-column: center-start / center-end;
  margin-top: 10rem;
  margin-bottom: 10rem;

  @include respond(phone) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  & h2 {
    margin-bottom: 8rem;

    @include respond(phone) {
      margin-bottom: 5rem;
    }
  }

  &-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include respond(ipad-pro) {
      row-gap: 5rem;
    }

    &__map {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(ipad-pro) {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
      }
    }

    &__text {
      grid-column: 2 / 3;
      grid-row: 1 / 2;

      display: flex;
      flex-direction: column;
      gap: 4rem;
      align-items: center;

      @include respond(ipad-pro) {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
      }

      &--card {
        background-color: rgba($color-primary-dark, $alpha: 0.8);
        width: 80%;
        border-radius: 10px;
        padding: 2.5rem 4rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.map {
  width: 85%;
  height: 50rem;
  border-radius: 15px;
}
