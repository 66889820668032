@import "../../styles/global.scss";

.container {
  grid-column: full-start / full-end;
  grid-row: 5 / 6;

  display: grid;
  grid-template-columns: repeat(3, minmax(25rem, 1fr));
  grid-template-rows: repeat(2, min-content);

  @include respond(tab-port) {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }

  &-img {
    object-fit: cover;
    width: 100%;
    height: 400px;

    @include respond(phone) {
      height: 250px;
    }

    @include respond(tab-port) {
      height: 300px;
    }
  }
}
