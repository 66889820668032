@import "../../styles/global.scss";

.container {
  background-color: black;
  grid-column: full-start / full-end;
  grid-row: 3 / 4;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include respond(tab-port) {
    row-gap: 5rem;
  }
}

.images {
  grid-column: 1 / 2;
  grid-column: 1 / 2;

  @include respond(tab-port) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  display: grid;

  &-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &-2 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    height: 40%;
    width: 60%;
    object-fit: cover;
    margin-top: auto;
    margin-left: 70%;

    border: solid 1.5rem rgba($color-primary-dark, $alpha: 1);

    @include respond(tab-land) {
      height: 25%;
      width: 70%;
    }

    @include respond(tab-port) {
      height: 35%;
      width: 35%;
      margin-left: auto;

      border: solid 1rem rgba($color-primary-dark, $alpha: 1);
    }
  }
}

.box {
  grid-column: 2 / 3;
  grid-row: 1 / 2;

  display: grid;
  grid-template-rows: repeat(3, min-content);
  gap: 2rem;
  justify-items: center;
  padding-top: 5rem;

  @include respond(tab-port) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  & p {
    width: 70%;
    padding: 2rem 3rem;
    background-color: rgba($color-primary-light, $alpha: 0.15);
    text-align: center;
    border-radius: 1rem;
    line-height: 1.4;
  }
}

.logo {
  @include respond(phone) {
    width: 45%;
  }
}
