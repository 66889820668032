@import "../../styles/global.scss";

.container {
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  border-radius: 2rem;

  display: grid;
  grid-template-rows: 1fr min-content;
}

.content {
  grid-row: 1 / 2;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  overflow: hidden;

  display: grid;
}

.img {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;

  // animation: moveInLeft;
  // animation-duration: 20s;
  // animation-timing-function: ease-out;
  // animation-iteration-count: infinite;
}

.layer {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  height: 100%;
  max-width: 100%;
  z-index: 2;
  background-color: rgba($color-primary-dark, $alpha: 0.35);
}

.text {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  z-index: 10;
  margin-left: 5%;
  margin-right: 5%;

  align-self: center;
  display: grid;
  row-gap: 2rem;
}

.btn {
  justify-self: self-start;
}

.heading {
  width: 60%;
  font-size: 4.5rem;
  line-height: 1.1;

  @include resetH1;

  @include respond(phone) {
    font-size: 4rem;
  }
}

// Dots

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  gap: 0.8rem;
}

.dot {
  height: 1.6rem;
  width: 1.6rem;
  background-color: rgba($color-primary-light, $alpha: 0.3);
  border-radius: 50%;
}

.dot-selected {
  height: 1.8rem;
  width: 1.8rem;
  background-color: white;
  border-radius: 50%;
}

@keyframes moveInLeft {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.05);
  }

  0% {
    transform: scale(1);
  }
}
