@import "../../styles/global.scss";

.container {
  grid-column: full-start / full-end;
  background-color: $color-primary-dark;

  display: grid;
  grid-template-rows: min-content 1fr;

  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start] repeat(
      6,
      [col-start] minmax(min-content, 20rem) [col-end]
    )
    [center-end] minmax(6rem, 1fr) [full-end];
}
