/**
 * Requires
 * /settings/palette
 * /settings/fonts
 */

@mixin headingFontFamily {
  /* Typography */
  font-family: $header-font-family;
}

@mixin headingElement {
  /* Box-model */
  margin: 0;

  /* Typography */
  @include headingFontFamily();
  color: $color-primary-light;
  font-weight: 600;
}

@mixin resetH1 {
  @include headingElement();
}

@mixin resetH2 {
  @include headingElement();
}

@mixin resetH3 {
  @include headingElement();
}

@mixin resetH4 {
  @include headingElement();
}

@mixin resetH5 {
  @include headingElement();
}

@mixin resetH6 {
  @include headingElement();
}

@mixin resetHeadings {
  h1 {
    @include resetH1();
  }

  h2 {
    @include resetH2();
  }

  h3 {
    @include resetH3();
  }

  h4 {
    @include resetH4();
  }

  h5 {
    @include resetH5();
  }

  h6 {
    @include resetH6();
  }
}
