@import "../../styles/global.scss";

.container {
  grid-row: 7 / 8;
  grid-column: full-start / full-end;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  margin-bottom: 4rem;
}

.logo {
  @include respond(phone) {
    width: 45%;
  }
}

.text {
  color: black;
  font-size: 2rem;
}

.box {
  display: flex;
  gap: 1rem;

  @include respond(phone) {
    gap: 2rem;
  }
}

hr {
  width: 80%;
  height: 4px;
  background-color: black;
  border-radius: 5px;
}

.copy {
  color: black;
  font-size: 1.5rem;
}
