@import "elements/body";
@import "elements/headings";
@import "settings/fonts";
@import "settings/palette";
@import "settings/screen-size";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  overflow: visible;
  overflow-x: hidden;
  box-sizing: border-box;
  font-size: 62.5%; // 10px / 16px Browser Deafult = 62.5%  -> 1rem = 10px

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    font-size: 50%; //1rem = 8px, 8/16 = 50%
  }
}

body {
  /* Typography */
  @include bodyElement();
}

// TYPOGRAPHY

h2 {
  color: black;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  font-family: $header-font-family;
}

h4 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.8rem;
}
